<!-- 商品管理 -->
<template>
    <div class="zt-page-content flex flex-v">
        <div style="min-width:900px;overflow-y:auto;" class="flex-1">
            
            <div v-if="list" class="listCon">
                <div class="listCon-item" v-for="(item,idx) in list" :key="idx">
                    <!-- <div class="listCon-time">{{item[0].time}}</div> -->
                    <div class="listCon-header">{{item[0].item.factoryComp.name}}</div>
                    <div class="listCon-table">
                        <el-table :data="item" style="font-size: 14px;" ref="_table">
                            <el-table-column style="text-align: center" prop="date1" label="产品" width="420">
                                <template slot-scope="scope">
                                    <div class="flex">
                                        <div style="padding:0 20px 0 10px" class="flex-justify-between">
                                            <template>
                                                <el-checkbox @change="selectSingle($event,scope.row)" v-model="scope.row.checked"></el-checkbox>
                                            </template>
                                        </div>
                                        <div @click="gotoDetail(scope.row.item)" class="coverImg flex-justify-between">
                                            <div v-if="scope.row.item.photoRender" class="bg_img"
                                            :style="{backgroundImage: 'url(' +imgUrl+scope.row.item.photoRender[0]+'!width_250px'+ ')'}"></div>
                                        </div>
                                        <div class="flex-1 flex-justify-between">
                                            <h3 @click="gotoDetail(scope.row.item)" class="text-name ellipsis">{{scope.row.item.name}}</h3>
                                            <!-- <div style="text-align:left;margin-bottom:5px;">
                                                <span v-if="scope.row.item.priceType<2">设计师合作款</span>
                                                <span v-if="scope.row.item.priceType==2">工厂自有款</span>
                                            </div> -->
                                            <!-- <div v-if="scope.row.item.priceType<2" class="text-type">设计师：{{scope.row.item.creater.name}}</div>
                                            <div v-if="scope.row.item.priceType<2" class="text-company">{{scope.row.item.createdByComp.name}}</div> -->
                                            <div class="item-sku">
                                                <div v-if="scope.row.itemSku.material && scope.row.itemSku.material.name" class="flex">
                                                    <div>材质：</div><div>{{scope.row.itemSku.material.name}}</div>
                                                </div>
                                                <div v-if="scope.row.itemSku.color && scope.row.itemSku.color.name" class="flex">
                                                    <div>颜色：</div><div>{{scope.row.itemSku.color.name}}</div>
                                                </div>
                                                <div v-for="(item1,idx1) in scope.row.itemSku.catAttrValues" :key="idx1" class="flex">
                                                    <div v-if="item1.attr">{{item1.attr.name}}：</div>
                                                    <div v-if="item1.value">{{item1.value.value}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="stock" width="150px" label="采购数量">
                                <template slot-scope="scope">    
                                    <!-- <div>{{scope.row.count}}</div> -->
                                    <el-input-number size="mini" @change="handleChange(scope.row,idx,scope.$index)" v-model="scope.row.count" :min="1"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="stock" label="采购单价">
                                <template slot-scope="scope">    
                                    <div>￥{{scope.row.price | priceFilt}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column class="shop-style" prop="date5" label="发货时间">
                                <template slot-scope="scope">
                                    <div>{{scope.row.createdAt | moment("YYYY-MM-DD")}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column class="shop-style" prop="date6" label="小计">
                                <template slot-scope="scope">
                                    ￥{{scope.row.count*scope.row.price | priceFilt}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="shop-cart">
            <div class="flex">
                <div class="shop-cart-checked flex-1">
                    <span><template>
                        <el-checkbox @change="selectAll($event)" v-model="allChecked">全选</el-checkbox>
                    </template></span>
                    <span @click="delSelect()" style="margin-left: 20px">删除</span>
                </div>
                <div class="shop-cart-num">数量总计：<span>{{totalNum}}</span>件</div>
                <div class="shop-cart-price">总价：<span>{{totalPrice}}</span></div>
                <div class="shop-cart-button" style="text-align: center">
                    <el-button style="color: #fff;" type="text" @click="gotoPay()">结算（<span>{{(allSelectedId || '').length}}</span>）</el-button>
                </div>
            </div>
        </div>
        <el-dialog :title="popTitle" :visible.sync="popVisible">

        </el-dialog>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {  } from '@/service/company';
    import { IMG_URL_PRE } from '@/config';
    import { listPurCar,delPurCar,updatePurCar,countPurCar } from '@/service/sale';

    export default {
        components: {},
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                popVisible: false,
                popTitle: '',
                popType:null,
                list: [],
                tabList:[
                    {name:"设计师合作款"},
                    {name:"工厂自有款"},
                ],
                tabIndex:"0",
                input2:"",
                queryOpt:{
                    page:{
                        pageNumber:1,
                        pageCount:15,
                    },
                    where:{
                        // priceType:[1,2],
                    },
                    // order: [
                    //     ["desc"]
                    // ],
                },
                total:0,
                allChecked:false,
                allSelectedId:[],
                allSelectedGoods:[],
            }
        },
        watch: {
            queryOpt: {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        computed: {
            totalNum() {
                var total = 0;
                this.list.forEach(e => {
                    e.forEach((n)=>{
                        if (n.checked) {
                            // total+=n.stock.reduce((pre, item) => {
                            //     return pre+item.count;
                            // },0);
                            total+=n.count;
                        }
                    })
                });
                return total;
            },
            totalPrice() {
                var total = 0;
                this.list.forEach(e => {
                    e.forEach((n)=>{
                        if (n.checked) {
                            // total+=n.stock.reduce((pre, item) => {
                            //     return pre+item.count*item.price;
                            // },0);
                            total+=n.amount;
                        }
                    })
                });
                // total = total.toFixed(2);
                total = Math.floor(total * 100) / 100;
                return total;
            },
        },
        async created() {
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
        },
        mounted() {
            this.refreshItems();
        },
        methods: {
            gotoDetail(item) {
                console.log(item);
                if (item.priceType==2) {
                    this.$router.push("/sale/mall/home/factory_detail/"+item.id);
                } else {
                    this.$router.push("/sale/mall/home/design_detail/"+item.id);
                }
            },
            refreshItems() {
                const _loading = this.$loading({
                    target:this.$refs.list,
                    lock: true,
                    text: "拼命加载中....",
                });
                console.log('refreshItems');
                listPurCar(this.queryOpt).then(rst => {
                    this.total=rst.count;
                    let arr = rst.rows;
                    let map = new Map();
                    arr.forEach(item => {
                        map.has(item.factoryId) ? map.get(item.factoryId).push(item) : map.set(item.factoryId, [item]);
                        
                    })
                    let list = [...map.values()];
                    this.list = list;
                    this.list.forEach((e,idx) => {
                        e.forEach((n,idx1)=>{
                            if (this.allSelectedId && this.allSelectedId.length>0) {
                                if (this.allSelectedId.indexOf(n.id)!==-1) {
                                    let index = this.allSelectedId.indexOf(n.id)
                                    this.$set(n,'checked',true);
                                }
                            } else {
                                this.$set(n,'checked',false);
                            }
                        })
                    });
                    console.log(this.list);
                    _loading.close();
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                    _loading.close();
                });
            },
            buddle_sort(arr) {
                let len = arr.length - 1;
                for (let j = 0; j < len; j++) {
                    for (let i = 0; i < len - j; i++) {
                        if (arr[i].start > arr[i + 1].start) {
                            [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]]
                        }
                    }
                }
            },
            filt(list,count) {
                let flag = true;
                for (let i=0; i<list.length-1; i++) {
                    if (count > list[i].end && count < list[i+1].start) {
                        flag = false;
                    } else {
                        flag = true;
                    }
                }
                return flag;
            },
            handleChange:_.debounce(function(item){
                console.log(item);
                if (item) {
                    this.buddle_sort(item.itemSku.prices);
                    if (!this.filt(item.itemSku.prices,item.count)) {
                        this.refreshItems();
                        this.$message.error("请选择范围内的数量");
                        return;
                    } else {
                        this.addShopCart(item);
                    }
                }
                // this.addShopCart(item);
                console.log(666);
            },500),
            addShopCart(item) {
                updatePurCar(item).then(rst => {
                    console.log('updatePurCar',rst);
                    this.getShopNum();
                    this.refreshItems();
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            getShopNum() {
                countPurCar().then(rst => {
                    console.log('countPurCar',rst);
                    this.$store.state.ShopNum = rst;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            goto(path){
                this.$router.push(path);
            },
            gotoPay() {
                if (this.$store.state.loginUser.comp.cert_stat!=2) {
                    this.$message.error("企业未认证");
                    return;
                }
                if (this.allSelectedId.length>0) {
                    let allSelectedGoods = [];
                    this.allSelectedId.forEach((e)=>{
                        this.list.forEach((n)=>{
                            let obj=n.find((value)=>value.id==e);
                            if (obj) allSelectedGoods.push(obj);
                        })
                    })
                    console.log(allSelectedGoods);
                    sessionStorage.setItem("allSelectedGoods",JSON.stringify(allSelectedGoods));
                    this.$router.push({
                        path:'/sale/pur/cgc/check',
                        query:{}
                    });
                } else {
                    this.$message.error("请选择商品");
                }
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            refreshRows() {
                listPurCar().then(u=>{
                    this.rows=u.list;
                    this.total=u.total;
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            //点击单选按钮
            selectSingle(event,item) {
                console.log(event);
                if (event == true) {
                    this.allSelectedId.push(item.id);
                    this.allSelectedGoods.push(item);
                    let length=0;
                    this.list.forEach((e)=>{
                        // e.forEach((n)=>{
                        //     // // n.checked = true;
                        //     // this.$set(n,'checked',true);
                        //     // this.allSelectedId.push(n.id);
                        //     // this.allSelectedGoods.push(n);
                        // })
                        length+=e.length;
                    })
                    if (this.allSelectedId && this.allSelectedId.length == length) {
                        this.allChecked = true;
                    }
                } else {
                    for (var i = 0; i < this.allSelectedId.length; i++) {
                        if (this.allSelectedId[i] === item.id) {
                            this.allSelectedId.splice(i, 1);
                            this.allSelectedGoods.splice(i, 1);
                            this.allChecked = false;
                            break;
                        }
                    }
                }
                // console.log(this.allSelectedId);
            },
            selectAll(event) {
				if(event) {//全选
                    this.allSelectedId = [];
                    this.allSelectedGoods = [];
                    this.list.forEach((e)=>{
                        e.forEach((n)=>{
                            // n.checked = true;
                            this.$set(n,'checked',true);
                            this.allSelectedId.push(n.id);
                            this.allSelectedGoods.push(n);
                        })
                    })
                } else {  //取消全选
                    this.allSelectedId = [];
                    this.allSelectedGoods = [];
					this.list.forEach((e)=>{
                        e.forEach((n)=>{
                            // n.checked = false;
                            this.$set(n,'checked',false);
                        })
                    })
				}
                this.allChecked = event;
                console.log(this.allSelectedId);
			},
            //批量删除
            delSelect() {
                if (this.allSelectedId.length>0) {
                    this.$confirm('确认从购物车删除吗?', '温馨提示', { confirmButtonText: '确定',
                    cancelButtonText: '取消', type: 'warning',
                    }).then(() => {
                        this.delet();
                    });
                } else {
                    this.$message.error("请选择商品");
                }
            },
            delet() {
                delPurCar(this.allSelectedId).then(rst => {
                    console.log('delPurCar',rst);
                    this.$message.success("删除成功");
                    this.refreshItems();
                    this.getShopNum();
                    this.list.forEach((e)=>{
                        e.forEach((n,idx)=>{
                            if (this.allSelectedId.indexOf(n.id)!==-1) {
                                //从allSelectedGoods数组中也需要删除
                                let index = this.allSelectedId.indexOf(n.id);
                                this.allSelectedId.splice(index,1);
                                this.allSelectedGoods.splice(index,1);
                            }
                            this.$set(n,'checked',false);
                            this.allChecked=false;
                        })
                    })
                    // this.allSelectedId = [];
                    // this.allSelectedGoods = [];
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>

    div>>> .el-table {
        border-top: 1px solid #E6E6E6;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }
    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-table th:first-child div.cell {
        text-align: left;
    }

    /*----选项卡----*/
    .tab_menu {
        background: #fff;
        padding: 0 20px;
        margin: 20px;
    }
    .tab_menu .menu_item{
        height: 70px;
        line-height: 70px;
        margin-right:70px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
    /*----------页面内部头------------*/
    .sort {
        overflow: auto;margin: 20px 20px 0 20px;
        background: white;
    }
    .sort-left {
        background: rgba(255,255,255,1);padding: 20px;line-height: 40px;
    }
    .sort-left span {
        font-size:14px;color:#808080;
        margin-right: 50px;
    }
    .sort-left span:first-child {
        font-size:18px;color:#292929;
    }
    /*--------------内容--------------*/
    .listCon {
        padding: 20px 0px 20px 20px;
    }
    div.listCon>>> .el-input-number--mini{
        width: 110px;
        overflow: hidden;
        margin:0 10px;
    }
    div.listCon>>> .el-input--mini .el-input__inner {
        width: 110px;
    }
    .listCon-time {
        width: 100%;
        height: 70px;
        line-height: 70px;
        padding-left: 20px;
        font-size: 14px;
        color: #4D4D4D;
    }
    .listCon-header {
        width: 100%;
        height: 49px;
        line-height: 49px;
        padding-left: 20px;
        background: #DBE1F6;
    }
    .listCon-table {
        padding: 20px;
        background: #fff;
    }
    .coverImg {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        cursor: pointer;
    }
    .text-name {
        text-align: left;
        font-size: 16px;
        font-weight:bold;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .text-type,.text-company,.text-mi {
        text-align: left;
        font-weight:500;
        font-size: 14px;
        color:rgba(128,128,128,1);
        margin-bottom: 5px;
    }
    .text-mi {
        color: #4D4D4D;
    }
    .text-company:last-child {
        margin-bottom: 0;
    }

    /*--------结算-------*/
    .shop-cart {
        width: auto;
        min-width:960px;
        height: 60px;
        line-height: 60px;
        background: #d1d9e29e;
        box-shadow:0px 2px 4px 0px rgba(209,217,226,1);
        padding: 0 0 0 20px;
        position: relative;
        z-index: 1;
        margin: 20px;
    }
    .shop-cart>div {
        /* background: #D1D9E2; */
    }
    .shop-cart>div>div {
        font-size: 14px;
        color: #808080;
        margin-left: 40px;
    }
    .shop-cart div.shop-cart-checked>span:first-child {
        font-size: 14px;
        color: #808080;
    }
    .shop-cart div.shop-cart-checked>span:last-child {
        font-size: 14px;
        color: #4D4D4D;
        cursor: pointer;
    }
    .shop-cart div.shop-cart-checked>span:hover {
        color: #409EFF;
    }
    .shop-cart div.shop-cart-num>span {
        font-size: 16px;
        color: #4D4D4D;
    }
    .shop-cart div.shop-cart-price>span {
        font-size: 18px;
        color: #D70000;
    }
    .shop-cart div.shop-cart-button {
        width: 100px;
        text-align: center;
        background: #5074EE;
        color: #fff;
        cursor: pointer;
    }
    
</style>